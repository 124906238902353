import { makeStyles } from "@material-ui/styles";
import { Button } from "@material-ui/core";
import { useRequireAuth } from "../../../hooks/UseAuth";
import { t } from "../../../services/LocaleService";
import { useEffect, useState } from "react";
import useApi from "../../../hooks/UseApi";

export default function Header() {
  const { user, logout } = useRequireAuth();
  const api = useApi();
  const [provider, setProvider] = useState<string | undefined>("");

  useEffect(() => {
    api.getProvider().then((response) => {
      setProvider(response.id);
    });
  }, []);

  const styles = useStyles();
  return (
    <div className={styles.header}>
      <Button onClick={logout}>
        {t("COMMON.ACTION_LOGOUT")} - {user}
      </Button>
      <div>ProviderID: {provider}</div>
    </div>
  );
}

const useStyles = makeStyles(() => ({
  header: {
    display: "flex",
    flexFlow: "row",
    justifyContent: "space-between",
  },
}));
